.commentsWrapper {
  position: relative;
  padding: 10px;
  border-radius: 4px;
  background-color: rgb(255, 232, 197);
}

.list {
  display: flex;
  flex-direction: column;

  &Item {
    margin-bottom: 10px;
    background-color: rgb(255, 241, 221);
    padding: 10px;

    &Footer {
      display: flex;
    }

    &Text {
      font-size: 16px;
    }

    &Date {
      font-size: 8px;
    }
  }
}

.commentInputWrapper {
  width: 100%;
  box-sizing: border-box;
  //display: flex;
  //flex-direction: column;
  //align-items: flex-end;
  //margin-left: auto;
}

.comment {

  &Input {
    width: 100%;
    min-height: 150px;
    outline: none;
    border: 1px solid rgb(255, 169, 75);
    border-radius: 4px;
    padding: 5px;
    resize: vertical;
    box-sizing: border-box;
  }

  &Buttons {
    display: flex;
    justify-content: flex-end;
  }

  &Button {
    width: 100px;
    border: none;
    padding: 1px 10px;
    border-radius: 4px;
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 4px;
    opacity: 1;

    &:hover {
      cursor: pointer;
      opacity: .8;
    }

    &Text {
      color: rgb(255, 232, 197);
      font-size: 12px;
      display: inline-block;
      margin-right: 5px;
    }

    &Close {
      width: 20px;
      height: 20px;
      position: absolute;
      top: -10px;
      right: -10px;
      border-radius: 50%;
      padding: 0;
    }
  }
}
