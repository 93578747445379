@import url('https://fonts.googleapis.com/css2?family=Kurale&display=swap');

:root {
    --main: #ff8800;
    --main-medium: rgba(255, 136, 0, 0.49);
    --main-light: rgba(255, 136, 0, 0.17);
}


body {
    margin: 0;
    font-family: "Kurale", system-ui;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
    display: flex;
    width: 100%;
    justify-content: center;
}

.ql-editor {
    font-family: "Kurale", system-ui;
    font-weight: 400;
}

.ql-container {
    height: 90vh;
    flex-grow: 1;
    overflow-y: auto;
    padding-bottom: 40px;
}

.ql-snow .ql-picker.ql-header {
    width: 110px;
}

.ql-snow .ql-picker.ql-size {
    width: 115px;
}

.ql-toolbar.ql-snow {
    max-width: 993px;
    width: 100%;
    border: none;
    border-bottom: 1px solid #e7e7e7;
}

.ql-container.ql-snow {
    border: none;
}

.ql-tooltip.ql-hidden {
    display: none;
}

.container-html p {
    margin: 0;
}

.react-datepicker__day {
    position: relative;
}

.react-datepicker__day--selected {
    border-radius: 50%;
    background-color: #3dcc4a;

}

.react-datepicker__day--selected:hover {
    background-color: transparent;
}
