.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 4px 2px #efefef;
  border-radius: 8px;
  border: 1px solid #e7e7e7;
  max-width: 993px;
  width: 100%;
  height: 90vh;
  margin-top: 20px;
}

.loader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(231, 231, 231, 0.07);

  display: flex;
  align-items: center;
  justify-content: center;

}
