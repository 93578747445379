.button {
  border-width: 2px;
  border-style: solid;
  background-color: rgb(69, 69, 69);
  transition: 0.3s;
  transform: translateY(0);

  &:hover {
    cursor: pointer;
    background-color: rgb(77, 47, 9);
    transition: 0.3s;
    transform: translateY(-3px);
    box-shadow: 0 5px 5px 1px #8d8d8d;
  }
}
