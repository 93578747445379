.wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.25);
}

.calendar {
  width: 243px;
  position: fixed;
  bottom: 200px;
  right: 50px;
  z-index: 201;

  &Item {
    border-radius: 50%;
    font-family: "Kurale", system-ui;
    background-color: transparent;

    position: relative;

    &Pink {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: red;
      color: #ffffff;
      font-family: "Kurale", system-ui;
      font-weight: bold;
      font-size: 10px;
      line-height: 10px;

      position: absolute;
      top: -2px;
      right: -2px;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    &Active {
      background-color: #4cd964;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  &Btn {
    position: fixed;
    right: 50px;
    bottom: 120px;
    border-radius: 50%;
    width: 50px;
    height: 50px;

    border-style: solid;
    background-color: rgb(69, 69, 69);
    transition: 0.3s;
    transform: translateY(0);

    &Unread {
      position: absolute;
      bottom: -10px;
      min-width: 20px;
      width: auto;
      height: 20px;
      border-radius: 10px;
      background-color: red;
      color: #ffffff;
      font-family: "Kurale", system-ui;
      font-size: 14px;

      &:empty {
        display: none;
      }

    }

    &:hover {
      cursor: pointer;
      background-color: rgb(77, 47, 9);
      transition: 0.3s;
      transform: translateY(-3px);
      box-shadow: 0 5px 5px 1px #8d8d8d;
    }


  }
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: transparent;
  z-index: 100;
}
