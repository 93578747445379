.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 4px 2px #efefef;
  border-radius: 8px;
  border: 1px solid #e7e7e7;
  max-width: 993px;
  width: 100%;
  height: 90vh;
  margin-top: 20px;
  // overflow-y: hidden;
}

.editor {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  &Inner {
    position: relative;
    z-index: 100;
    padding: 20px;
    background-color: rgba(231, 231, 231, 0.4);
  }

  &Close {
    position: absolute;
    z-index: 100;
    top: -10px;
    right: -10px;
    cursor: pointer;
  }
}

.toolbar {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  &Save {
    width: 120px !important;
    padding: 0 10px !important;
    border-width: 1px !important;
    border-style: solid !important;
    border-radius: 4px;
    line-height: 14px;
    margin-left: auto;
  }
}

.emoji {
  position: relative;


  &Picker {
    position: absolute;
    z-index: 101;
  }

  &Overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.17);
    z-index: 100;
  }

  &Button {
    background-color: transparent;
    border: none;
    outline: none;
    //width: 30px;
    //height: 30px;
    display: flex;
    align-items: flex-start;
    font-size: 14px;

    &:hover {
      cursor: pointer;
    }
  }
}

.background {
  background-color: var(--main-medium);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../../assets/images/bg.png");
    background-size: contain;
    background-position: center;
    opacity: 0.7;
    z-index: 2;
  }
}
