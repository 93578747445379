.listWrapper {
  max-width: 993px;
  width: 100%;

  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;


  &Inner {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    padding: 20px;
    overflow-y: auto;
    background-color: rgba(231, 231, 231, 0.4);
  }
}

.background {
  background-color: var(--main-medium);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../../assets/images/bg.png");
    background-size: contain;
    background-position: center;
    opacity: 0.7;
    z-index: 2;
  }
}

.post {
  &Inner {
    position: relative;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 20px;
    background-color: rgba(255, 255, 255, 0.19);

    &Delete {
      background-color: transparent;
      border: none;
      outline: none;
      font-family: "Kurale", system-ui;
      font-size: 12px;
      padding: 5px 10px;
      opacity: 0;
      transition: 0.3s;

      &:hover {
        cursor: pointer;
      }
    }

    &:hover .postInnerDelete {
      background-color: transparent;
      border: none;
      outline: none;
      font-family: "Kurale", system-ui;
      font-size: 12px;
      padding: 5px 10px;
      opacity: 1;
      transition: 0.3s;
    }

  }

  &Footer {
    display: flex;
    justify-content: space-between;
  }


}

.title {
  font-size: 18px;
  font-family: "Kurale", system-ui;
  color: #000000;
  margin: 0 0 20px;
}

.time {
  display: flex;
  align-items: flex-end;

  &Subtext {
    font-size: 12px;
    font-family: "Kurale", system-ui;
    font-weight: 300;
    color: #000000;
    display: inline-block;
    margin-right: 5px;
  }

  &Text {
    font-size: 14px;
    font-family: "Kurale", system-ui;
    color: #000000;
  }
}

.toolbar {
  &Button {
    position: fixed;
    right: 50px;
    border-radius: 50%;
    width: 50px;
    height: 50px;

    border-style: solid;
    background-color: rgb(69, 69, 69);
    transition: 0.3s;
    transform: translateY(0);

    &:nth-child(1) {
      bottom: 50px;
    }

    &:nth-child(2) {
      bottom: 120px;
    }

    &:hover {
      cursor: pointer;
      background-color: rgb(77, 47, 9);
      transition: 0.3s;
      transform: translateY(-3px);
      box-shadow: 0 5px 5px 1px #8d8d8d;
    }
  }
}

.control {
  display: flex;
  padding: 5px;
  opacity: 0;
  animation: show .3s ease-in-out forwards;

  &Button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin: 0 2px;
  }
}

@keyframes show {
  from {
    opacity: 0
  }
  to {
    opacity: 1
  }
}
