.messageControl {
  position: absolute;
  bottom: -10px;
  right: -10px;
  height: 26px;
  background-color: rgba(141, 141, 141, 0.32);
  border-radius: 12px;
  border: 1px solid rgba(141, 141, 141, 0.86);;

  &Item {
    cursor: pointer;
    padding: 0 3px;

    & svg {
      transform: scale(1);
    }

    &:hover svg {
      transform: scale(1.06);
    }
  }
}

.item {
  margin-bottom: 10px;
  background-color: var(--main-light);
  border-radius: 8px;

  max-width: 70%;
  margin-right: auto;
  margin-left: 0;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  position: relative;
  box-sizing: border-box;

  &Author {
    margin-left: auto;
    margin-right: 0;
    background-color: white;
  }

  &Body {
    width: 100%;
    padding: 10px 10px 0;
  }

  &Footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 2px;
    margin-top: 10px;

    background-color: var(--main-light);
    padding: 5px 10px 3px;
    box-sizing: border-box;
  }

  &Name {
    font-size: 12px;
    margin: 0 10px 0 0;
    color: #8d8d8d;
  }

  &DateTime {
    background-color: rgba(0, 0, 0, 0.09);
    padding: 0 2px;
    display: flex;
    align-items: center;
    border-radius: 4px;
  }

  &Text {
    font-size: 16px;
    display: inline-block;
  }

  &Date {
    font-size: 8px;
    display: inline-block;
    margin: 0 2px;
    font-family: "Kurale", sans-serif;
  }
}
